import React, { Component } from 'react'

export class NotFound extends Component {
    render() {
        return (
            <div>
                <h1>MY Not Found PAGE 404</h1>
            </div>
        )
    }
}

export default NotFound
